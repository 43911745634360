.header-fixed .app-header {
  /* position: fixed; */
  z-index: 30 !important;
  width: 100% !important;
}

.sidebar-fixed .sidebar {
  /* position: fixed; */
  z-index: 30 !important;
  /* width: 200px !important; */
  /* height: 100vh; */
}

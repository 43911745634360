// Here you can add other styles
.app-body {
  overflow-y: hidden;
  margin-top: 13vh !important;
}

*:focus {
  outline: none;
  box-shadow: none !important;
}

input:focus,
textarea:focus,
select:focus {
  border-color: #e4e7ea !important;
}

button:focus {
  outline: none;
}

.rounded-top {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.rounded-bottom {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.loader {
  color: #817b66; //#888989
  min-height: 325px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 10px;
}

.loader .fa-spin {
  font-size: 42px;
  margin: 0 !important;
}

.loader #message {
  margin: 0 0 0 8px;
}

.help-block {
  color: rgb(234, 82, 101) !important;
}

#login-page p {
  color: #ea6d7d !important;
}

#login-button {
  color: #ffffff;
  background-color: #ea5265;
  border-color: #ec455a;
  float: right;
}

#recover-password-link {
  color: #ea5265;
  background: none;
  border: none;
}

.navbar {
  height: 13vh;
  padding: 0 30px 0 0;
}

.navbar p {
  margin: 1vh 3px 0 0;
  display: inline-block;
}

#add-opportunity {
  color: #e75412;
  font-size: 2.3rem;
  width: 38px;
  height: 38px;
}

.navbar-icon {
  margin: 2vh 0 0 2vh !important;
}

.navbar-icon #icon-bell {
  position: relative;
  top: 6px;
}

.navbar-icon i {
  font-size: 18px;
}

.app-header .nav-item .nav-link > .img-avatar {
  height: 37px;
  width: 37px;
  margin: 0 1vw 0 3vw;
}

.main .container-fluid {
  padding: 3.5vh 20px 0 20px;
}

.text-value {
  font-size: 24px;
}

.sidebar {
  padding-top: 5vh;
  background-color: #e65f58;
}

.sidebar .nav-link.active {
  background-color: rgba($color: #fff, $alpha: 0.8) !important;
  color: #e65f58 !important;
}

.sidebar .nav-link .nav-icon {
  color: #fff;
}

.sidebar .nav-link.active .nav-icon {
  color: #e75f57;
}

.sidebar .nav-item :hover {
  //background-color: rgba($color: #000000, $alpha: 0.4);
  background-color: rgba($color: #fff, $alpha: 0.1);
  color: #ffffff;
}

.sidebar .nav-dropdown.open {
  background: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
}

.sidebar .nav-dropdown-items .nav-link {
  padding-left: 24px;
}

// .custom-modal .modal-content {
//     border: none;
// }

.sidebar .nav-dropdown-toggle::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

#dasboard-comingsoon-page {
  flex-direction: column !important;
  justify-content: center;
  height: 82vh;
  min-height: 82vh;
}

#dasboard-comingsoon-page #message {
  color: #888989;
}

#dasboard-comingsoon-page #description {
  color: #888989;
}

.custom-modal .card {
  margin-bottom: 0;
}

.modal-header {
  align-content: center;
  display: block !important;
}

.modal-header h1 {
  margin-bottom: 0;
}

.modal-header h6 {
  margin-bottom: 0;
}

.custom-modal .card-body {
  padding-top: 30px;
}

.custom-modal #interest-theme {
  padding: 20px 0;
  margin-bottom: 0;
}

.custom-modal #interest-theme > div:nth-child(2) {
  display: inherit;
}

.custom-modal #interest-theme #checkbox-container {
  display: flex;
  justify-content: center;
  margin-bottom: 44px;
}

.custom-modal .card-footer div:first-child {
  float: right;
}

#interest-modal-submit {
  color: #ffffff;
  background-color: #8bbf42;
  border-color: rgb(170, 214, 108);
}

.cancel {
  margin-right: 15px;
}

.interest-card {
  height: 155px;
  // background-image: url('/flowerdecoration-compressored.png');
}

.interest-card .card-body {
  padding: 10px 15px 0 15px;
}

.blue-card {
  background-color: #535c88;
}

.warm-orange-card {
  background-color: #9e5705;
}

.green-card {
  background-color: #526f29;
}

.orange-card {
  background-color: #9c310f;
}

.cool-orange-card {
  background-color: #a43c37;
}

.red-card {
  background-color: #a43e4b;
}

.interest-card .text-value {
}

.open-edit-card-button {
  position: absolute;
  bottom: 20px;
  right: 15px;
}

.open-edit-card-button .row {
  margin: 0;
}

.open-edit-card-button .edit {
  margin: 1px 0 0 15px;
}

.open-edit-card-button .fa-trash {
  position: relative;
  bottom: 0.5px;
}

#open-add-card {
  height: 155px;
  width: 90%;
  padding: 0 20px 0 20px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba($color: #ffffff, $alpha: 0.3);
  border: dashed 2px #8bbf42;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

#open-add-card i {
  font-size: 45px;
  color: #8bbf42;
  margin-top: 0 !important;
}

#open-add-card span {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 0 10px;
}

.edit-interest button {
  padding-top: 1px;
  padding-bottom: 1px;
}

// #confirmation-modal-container {
//     display: flex !important;
//     align-items: center;
// }

.confirmation-modal {
  height: inherit;
  display: flex;
  margin: 0 auto !important;
  justify-content: center;
  align-items: center;
}

.confirmation-modal .modal-content {
  width: 35vw;
  height: fit-content;
  border: none;
}

.confirmation-modal .modal-body {
  padding: 20px 27px 14px 27px;
}

.confirmation-modal .modal-body .cui-circle-x {
  color: #ea5265;
  text-align: center;
  margin: 16px 0 0 0 !important;
  font-size: 4rem !important;
}

.confirmation-modal .modal-body h1 {
  text-align: center;
  padding: 40px 0 0 0;
  color: #797979;
  font-size: 32px;
}

.confirmation-modal .modal-body h5 {
  text-align: center;
  padding: 10px 0 0 0;
  color: #797979;
  font-size: 16px;
}

.confirmation-modal .modal-footer {
  border: none;
  justify-content: center;
  padding: 16px 16px 44px 16px;
}

.confirmation-modal .modal-footer #cancel {
  color: #ffffff;
  background-color: #c4bcbd;
  border: none;
  border-radius: 4px;
  padding: 7px 15px;
  margin: 0 5px 0 0;
}

.confirmation-modal .modal-footer #delete {
  color: #ffffff;
  background-color: #ea5265;
  border: none;
  border-radius: 4px;
  padding: 7px 15px;
  margin: 0 0 0 5px;
}

.confirmation-modal .modal-footer .cui-circle-check {
  color: #ffc107;
  font-size: 32px !important;
  margin: 0 0 0 15px !important;
}

.confirmation-modal .modal-footer .cui-circle-x {
  color: #f86c6b;
  font-size: 32px !important;
  margin: 0 !important;
}

.confirmation-modal .modal-footer p {
  margin-bottom: 0;
}

.confirmation-modal .modal-footer #confirm-text {
  margin-right: 15px;
}

.toastr {
  min-width: 20vw;
}

#user-profile {
  width: 700px;
  border-radius: 4px;
}

.user-profile-header {
  min-height: 200px;
}

.user-profile-header > div {
  position: relative;
  top: 115px;
}

#user-profile .img-avatar {
  border: solid 2px rgba(255, 255, 255, 0.8);
  margin: 0 0 12px 0;
}

.user-profile-header #username {
  width: 100%;
}

.user-profile-header #username h1 {
  text-align: center;
}

.user-profile-body {
  height: 32vh;
  position: relative;
}

.user-profile-body > div {
  width: 100%;
  position: absolute;
  bottom: 25px;
}

.user-profile-body > div > div {
  width: fit-content;
  margin: 0 auto;
}

.user-profile-body > div > div > .cui-circle-x {
  position: relative;
  top: 5px;
}

.edit-user-info {
  text-align: center;
  padding: 0 0 55px 0;
}

.edit-user-info button {
  min-width: 164px;
  background: none;
  color: #f14e63;
  border-color: #f14e63;
}

.edit-user-info button:hover {
  color: #fff;
  background-color: #f14e63 !important;
  border-color: #f14e63;
}

.edit-user-info button:focus {
  box-shadow: none;
}

.edit-user-info #edit-profile-picture-button {
  height: 36px;
  margin: 0 !important;
}

.edit-user-info #edit-user-name-button .cui-user-female {
  font-size: 19px !important;
  position: relative;
  top: 2px;
}

.edit-user-info #changepassword-button {
  height: 36px;
}

.edit-user-info p {
  margin: 0 0 0 5px !important;
  display: inline-block;
}

.userprofile-card {
  background-color: #ffffff;
  padding: 30px;
  margin-top: -10px;
  min-height: 80vh;
}

#edit-user-profile-modal {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-user-info label {
  margin: 0 0 0 5px;
}

.edit-user-info #username h1 {
  display: inline-block;
  line-height: 52px;
  // margin-right: 27px;
  margin: 0;
  color: #394060;
}

.edit-user-info #username input {
  margin: 8px 8px 8px 0;
  padding-left: 10px;
  font-size: 17px;
  border-radius: 4px;
  border: solid 1px #cdcaca;
  border-width: 1px;
}

.edit-user-info #username button {
  background: none;
  border: none;
}

.edit-user-info #username button .cui-circle-check,
.edit-user-info #username .cui-circle-x {
  position: relative;
  top: 6px;
}

// .edit-user-info #username button .cui-circle-x {
//     position: relative;
//     top: 6px;
// }

.edit-user-info #username hr {
  width: 70%;
  margin-left: 0;
  margin-bottom: 0;
}

.edit-user-info #changepassword-button {
  border-radius: 5px;
}

.edit-user-info #changepassword-button p {
  font-size: 14px;
  margin-bottom: 0;
  display: inline-block;
}

.edit-user-info #changepassword-button .fa-key {
  font-size: 16px;
  margin: 0 !important;
}

.edit-user-info .password-input {
  display: block;
  margin-bottom: 15px;
  padding: 5px 0 2px 10px;
  border-radius: 5px;
  border: solid 1px rgb(205, 202, 202);
}

.edit-user-info #editpassword-form {
  width: fit-content;
}

.edit-user-info .action-buttons {
  float: right;
}

.edit-user-info .action-buttons button {
  padding: 2px 7px;
  border: none;
  border-radius: 4px;
}

.edit-user-info .action-buttons .cancel p {
  margin-bottom: 0;
}

.edit-user-info .action-buttons .submit p {
  margin-bottom: 0;
}

.user-profile-header div {
  width: fit-content;
}

.user-profile-header img {
  margin-top: 10px;
  width: 150px;
  height: auto;
}

.user-profile-header label {
  text-align: center;
  margin: 8px 0 18px 0;
  display: block;
}

#edit-profile-picture-button {
  border-radius: 4px;
  // padding: 3px 12px;
  margin: 0 10px 0 0;
  display: inline-block;
}

#edit-profile-picture-button .fa-edit {
  font-size: 16px;
  margin: 0 5px 0 0 !important;
  display: inline-block;
}

#edit-profile-picture-button p {
  margin: 0;
  display: inline-block;
}

#edit-profile-picture-icon {
  border-radius: 50%;
  padding: 6px;
}

#edit-profile-picture-icon .fa-edit {
  margin-top: 0 !important;
}

#previous-opportunities {
  padding: 0;
}

#previous-opportunities .card {
  padding: 30px 30px 15px 30px;
}

#add-sort-opportunities {
  margin: 0 0 30px 0;
}

#add-opportunities,
#review-opportunities {
  height: 50px;
  width: fit-content;
  padding: 7px 14px;
  border-radius: 30px;
  border: dashed 1px #ef8106;
  -webkit-box-shadow: 1px 3px 15px -10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 1px 3px 15px -10px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 3px 15px -10px rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

#add-opportunities i,
#review-opportunities i {
  color: #ef8106;
  font-size: 32px;
  margin: 0px 7px 0 0 !important;
}

#add-opportunities span,
#review-opportunities span {
  position: relative;
  bottom: 0;
  font-size: 16px;
  font-weight: 700;
}

#review-opportunities {
  margin: 0 0 0 25px;
}

#sort-opportunities {
  position: relative;
  top: 5px;
}

#sort-opportunities label {
  color: #65696c;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0;
}

#sort-opportunities #select {
  color: #65696c;
  font-size: 13.5px;
  height: fit-content;
  padding: 4px 5px;
}

#sort-opportunities .sort-opportunities {
  margin-right: auto;
  margin-left: auto;
}

select:focus {
  outline: none !important;
}

#previous-opportunities #categories {
  margin: 1px 0 0 0;
}

#previous-opportunities .item {
  margin-bottom: 30px;
}

#previous-opportunities img {
  width: 100%;
  height: 170px;
  margin: 0 0 10px 0;
}

#previous-opportunities #image-placeholder {
  color: #65696c;
  font-size: 120px;
  width: 100%;
  height: 170px;
  margin: 0 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#previous-opportunities .item .opportunity-name {
  font-size: 16px;
  margin-bottom: 0;
}

#previous-opportunities .item span {
  font-size: 13px;
  color: #2a343b;
}

#previous-opportunities .item .type {
  padding: 2px 14px;
  margin: 0 5px 0 0;
  border-top: 1px;
  border-bottom: 1px;
  border-radius: 16px;
  border-width: 1px;
  color: #fff;
}

#previous-opportunities .details {
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
  color: #ea5265;
}

#previous-opportunities .details p {
  font-size: 13px;
  margin: 0;
  display: inline-block;
}

#previous-opportunities .details .cui-arrow-right {
  font-size: 20px !important;
  margin: 0 0 0 5px !important;
  display: inline-block !important;
  position: relative;
  top: 5px;
}

#previous-opportunities .rc-pagination {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

#create-opportunity .card-header {
  text-align: center;
}

#opportunityType .form-chek {
}

#opportunityType input {
  height: 20px;
  width: 20px;
}

#create-opportunity .card-footer button {
  padding: 4px 16px;
  float: right;
}

#create-opportunity .card-footer .publish {
  color: #ffffff;
  background-color: #717ebc;
  border-color: rgb(146, 158, 214);
}

.publish:hover {
  background-color: #4655a3 !important;
  border-color: rgb(43, 59, 131) !important;
}

#create-opportunity .card-footer .save {
  background-color: #8bbf42;
  border-color: rgb(170, 214, 108);
  margin-right: 10px;
}

.save:hover {
  background-color: #6a9e21 !important;
  border-color: rgb(92, 139, 26) !important;
}

#create-opportunity .card-footer .close-modal {
  margin-right: 10px;
}

#opportunities-details {
  padding: 0;
}

#opportunities-details .card {
  // padding: 30px 30px 15px 30px;
  min-height: 100vh;
}

.opportunities-details-container {
  padding: 5px 200px;
  margin: 0;
  display: block;
}

.opportunities-details-container img {
  width: 85%;
  margin: 0 0 8px 0;
  max-height: 340px;
}

#image-placeholder-opportunity-details {
  width: 100%;
  margin: 0 0 8px 0;
  min-height: 360px;
  color: #65696c;
  font-size: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.opportunities-details-container .title {
  font-size: 22px;
  margin-bottom: 5px;
}

.opportunities-details-container .type {
  padding: 2px 14px;
  margin: 0 5px 0 0;
  border-top: 1px;
  border-bottom: 1px;
  border-radius: 16px;
  border-width: 1px;
  color: #fff;
}

.opportunities-details-container #opportunity-descripiton {
  margin-bottom: 30px;
  margin: 4px 0;
}

.opportunities-details-container #opportunity-descripiton p {
  margin-bottom: 5px;
}

.opportunities-details-container svg {
  fill: #5b5d60;
  width: 18px;
  height: 18px;
  margin: 0 4px 5px 10px;
}

#opportunity-resource {
  margin-bottom: 30px;
}

#opportunity-resource .title {
  font-size: 22px;
}

#website-url,
#video-url,
#pdf-url {
  margin-bottom: 10px;
}

#opportunity-resource label {
  font-size: 13px;
  margin-bottom: 0px;
}

#opportunity-resource a {
  display: block;
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 6px 6px;
  border-radius: 4px;
}

#review-edit-reject {
  padding: 0 0 0 15px;
}

#review-edit-reject button {
  color: #ffffff;
  padding: 6px 14px;
  border: solid 1px;
  border-radius: 10px;
}

#review-edit-reject #review-edit-button {
  background-color: #8bbf42;
  margin: 0 15px 0 0;
}

#review-edit-reject #reject-button {
  background-color: #ea5265;
}

.opportunities-details-container #opportunity-category .title {
  font-size: 22px;
}

.opportunities-details-container #opportunity-category .type {
  padding: 0 15px;
  margin: 0 10px 0 0;
  border-top: 1px;
  border-bottom: 1px;
  border-radius: 4px;
  background-color: #20a8d8;
  border-color: #63c2de;
  border-width: 1px;
  color: #fff;
}

#opportunities-details-navigation {
  padding: 15px 65px;
  margin: 0 0 20px 0;
}

#opportunities-details-navigation #back-button-container {
  display: flex;
  align-items: center;
}

#opportunities-details-navigation #back-button {
  background-color: #ef8106;
  border-color: #ee9737;
  color: #ffffff;
  padding: 6px 14px;
  border: solid 1px;
  border-radius: 10px;
}

#opportunities-details-navigation #navigation-buttons-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

#opportunities-details-navigation #navigation-buttons {
  margin: 0 auto 0 auto;
  position: relative;
}

#opportunities-details-navigation .prev-opportunity {
  background: none;
  border: solid 1px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 5px 7px 5px 5px;
  margin: 0 15px 0 0;
}

#opportunities-details-navigation .prev-opportunity .fa-chevron-left {
  font-size: 14px;
  position: relative;
  bottom: 2px;
}

#opportunities-details-navigation .next-opportunity {
  background: none;
  border: solid 1px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 5px 5px 5px 7px;
  margin: 0 0 0 15px;
}

#opportunities-details-navigation .next-opportunity .fa-chevron-right {
  font-size: 14px;
  position: relative;
  bottom: 1px;
}

#opportunities-details-navigation .prev-opportunity i,
#opportunities-details-navigation .next-opportunity i {
  margin: 0 !important;
}

#opportunities-details-navigation #actions-container {
  display: flex;
  align-items: center;
}

#opportunities-details-navigation actions-container #delete-opportunities,
#opportunities-details-navigation #edit-opportunities {
  display: inline-block;
}

#opportunities-details-navigation #delete-opportunities button,
#opportunities-details-navigation #edit-opportunities button {
  color: #ffffff;
  padding: 6px 14px;
  border: solid 1px;
  border-radius: 10px;
}

#opportunities-details-navigation #delete-opportunities button {
  background-color: #ea5265;
  border-color: rgb(238, 135, 147);
  margin: 0 10px 0 0;
}

#opportunities-details-navigation #edit-opportunities button {
  background-color: #8bbf42;
  border-color: #b8eb71;
}

#opportunities-details-navigation #edit-opportunities .fa-edit {
  margin: 0 3px 0 0 !important;
}

.rc-pagination-item {
  border-radius: 50% !important;
}

.rc-pagination-item:hover {
  border-color: #ef8106 !important;

  color: #ef8106 !important;
}

.rc-pagination-item-active {
  background-color: #ffffff !important;
  border-color: #ef8106 !important;
}

.rc-pagination-item-active:hover {
  // background-color: #E65F58 !important;
}

.rc-pagination-item a {
  color: #8a6969 !important;
}

.rc-pagination-item-active a {
  color: #ef8106 !important;
}

.rc-pagination-item-active:hover a {
  color: #e65f58;
}

.rc-pagination-options-quick-jumper input:hover {
  border-color: #ef8106 !important;
}

.rc-pagination-options-quick-jumper input:focus {
  border-color: #ef8106;
}

#searchResultItem:hover {
  color: #e9620e !important;
}

// OVERRIDES STOLEN FROM PWA
.z10 {
  z-index: 10;
}

.text-center {
  text-align: center;
}

.overflow-hidden {
  overflow: hidden;
}

/* POSITION HELPERS */
.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.position-fixed {
  position: fixed;
}

.pin-top,
.pin-all {
  top: 0;
}

.pin-bottom,
.pin-all {
  bottom: 0;
}

.pin-left,
.pin-all {
  left: 0;
}

.pin-right,
.pin-all {
  right: 0;
}

.d-inline {
  display: inline;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

/* FLEX HELPERS */
.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.center-center,
.items-center {
  align-items: center;
}

.items-start {
  align-items: start;
}

.center-center,
.justify-center {
  justify-content: center;
}

.justify-stretch {
  justify-content: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto;
  }
}
